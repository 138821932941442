
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class UserResumeInfoModal extends Vue {

    @Prop({
        default: () => new OM.UserExtractionResumeVM()
    }) item: OM.UserExtractionResumeVM;

    @Prop({
        default: 0
    }) rankingPosition: number;

    created() {
    }

}
