
import { AdminClient, GameClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import UserResumeInfoModal from '../modals/userResumeInfoModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Ranking extends Vue {

    isPublished: boolean = false;
    ranking: OM.UserExtractionResumeVM[] = [];
    activeRanking: number = 1;

    created() {
        this.init();
    }

    init() {
        GameClient.rankingBackoffice(this.activeRanking)
        .then(x => {
            this.ranking = x;
        })
    }

    setActiveRanking(active: number) {
        this.activeRanking = active;
        this.init();
    }

    getTimerValue(totalSeconds: number) {
        var totalMilliseconds = totalSeconds * 1000;
        var totalTimeInSeconds = Math.round(totalMilliseconds / 1000);
        var totalMinutes = Math.floor(totalTimeInSeconds / 60);
        var seconds = totalTimeInSeconds % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        var finalHours = this.returnData(hours);
        var finalMinutes = this.returnData(minutes);
        var finalSeconds = this.returnData(seconds);

        return finalHours + " : " + finalMinutes + " : " + finalSeconds;
    }

    returnData(input) {
        return input >= 10 ? input : '0' + input;
    }

    getInfo(item: OM.UserExtractionResumeVM, rankingPosition: number) {
        this.$opModal.show(UserResumeInfoModal, {
            rankingPosition: rankingPosition + 1,
            item: item
        })
        // AdminClient.getUserInfo(userId)
        // .then(x => {
        //     console.log("Informazioni");
        //     console.log(x);
        // })
    }

    declareWinner(userResumeIdentifier: string) {
        GameClient.declareWinner(userResumeIdentifier)
        .then(x => {
            this.init();
        })
    }
    voidWinner(userResumeIdentifier: string) {
        GameClient.voidWinner(userResumeIdentifier)
        .then(x => {
            this.init();
        })
    }

}
