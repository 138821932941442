import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal bg_grey d-flex justify-content-between align-items-center flex-column" }
const _hoisted_2 = { class: "page_padding text-center mb-5" }
const _hoisted_3 = { class: "page_padding w-100 mb-4" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "fw_700 mt-2 mb-3" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "fw_700 mt-2 mb-3" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "fw_700 mt-2 mb-3" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "fw_700 mt-2 mb-3" }
const _hoisted_13 = { class: "col-6" }
const _hoisted_14 = { class: "fw_700 mt-2 mb-3" }
const _hoisted_15 = {
  key: 1,
  class: "row"
}
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = {
  key: 0,
  class: "fw_700 mt-2 mb-3"
}
const _hoisted_18 = {
  key: 1,
  class: "fw_700 mt-2 mb-3"
}
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = {
  key: 0,
  class: "fw_700 mt-2 mb-3"
}
const _hoisted_21 = {
  key: 1,
  class: "fw_700 mt-2 mb-3"
}
const _hoisted_22 = { class: "page_padding d-flex justify-content-center w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "h1",
        class: "text-uppercase text-center fw_700",
        localizedKey: "backoffice_Dettaglio",
        text: "Dettaglio"
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_localized_text, {
            localizedKey: "backoffice_Nome Utente",
            text: "Nome Utente"
          }),
          _createVNode("h5", _hoisted_6, _toDisplayString(_ctx.item.name), 1)
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode(_component_localized_text, {
            localizedKey: "backoffice_Cognome Utente",
            text: "Cognome Utente"
          }),
          _createVNode("h5", _hoisted_8, _toDisplayString(_ctx.item.surname), 1)
        ]),
        _createVNode("div", _hoisted_9, [
          _createVNode(_component_localized_text, {
            localizedKey: "backoffice_Stage",
            text: "Stage"
          }),
          _createVNode("h5", _hoisted_10, _toDisplayString(_ctx.item.stage.text), 1)
        ]),
        _createVNode("div", _hoisted_11, [
          _createVNode(_component_localized_text, {
            localizedKey: "backoffice_Posizione in classifica",
            text: "Posizione in classifica"
          }),
          _createVNode("h5", _hoisted_12, _toDisplayString(_ctx.rankingPosition) + "°", 1)
        ]),
        _createVNode("div", _hoisted_13, [
          _createVNode(_component_localized_text, {
            localizedKey: "backoffice_Punti",
            text: "Punti"
          }),
          _createVNode("h5", _hoisted_14, _toDisplayString(_ctx.item.totalPoints), 1)
        ])
      ]),
      (_ctx.item.winnerData)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            type: "h3",
            class: "dividerTitle",
            localizedKey: "backoffice_Winner Info",
            text: "Winner Info"
          }))
        : _createCommentVNode("", true),
      (_ctx.item.winnerData)
        ? (_openBlock(), _createBlock("div", _hoisted_15, [
            _createVNode("div", _hoisted_16, [
              _createVNode(_component_localized_text, {
                localizedKey: "backoffice_Nome Accompagnatore",
                text: "Nome Accompagnatore"
              }),
              (_ctx.item.winnerData.secondPartecipantName)
                ? (_openBlock(), _createBlock("h5", _hoisted_17, _toDisplayString(_ctx.item.winnerData.secondPartecipantName), 1))
                : (_openBlock(), _createBlock("h5", _hoisted_18, "--"))
            ]),
            _createVNode("div", _hoisted_19, [
              _createVNode(_component_localized_text, {
                localizedKey: "backoffice_Cognome Accompagnatore",
                text: "Cognome Accompagnatore"
              }),
              (_ctx.item.winnerData.secondPartecipantSurname)
                ? (_openBlock(), _createBlock("h5", _hoisted_20, _toDisplayString(_ctx.item.winnerData.secondPartecipantSurname), 1))
                : (_openBlock(), _createBlock("h5", _hoisted_21, "--"))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_22, [
      _createVNode("button", {
        class: "btn btn-secondary w-50",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}